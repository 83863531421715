<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Liquidación de Aportes - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio: </label>
                    <v-select disabled placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" ></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Liquidación:">
                    <b-form-input class="text-center" disabled type="text" v-model="settlement_contributions.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Solicitud:">
                    <b-form-input type="date" disabled class="text-center"  v-model="settlement_contributions.application_date"></b-form-input>
                    <small v-if="errors.application_date"  class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Desembolso:">
                    <b-form-input type="date" disabled class="text-center"  v-model="settlement_contributions.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date"  class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cambiar Doc. Adjuntos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      disabled
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                

                
               
                <b-col md="10">
                  <b-form-group label="Observación:">
                    <b-form-textarea disabled type="text" v-model="settlement_contributions.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                
                 <div class="col-md-12">
                  <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="30%" class="text-center">Año</th>
                              <th width="40%" class="text-center">Meses</th>
                              <th width="15%" class="text-center">Aporte</th>
                        
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in settlement_contributions_detail" :key="it">
                            <tr>
                                <td class="align-middle text-center">{{ it + 1 }}</td>
                                <td class="align-middle text-left">{{ item.year }}</td>
                                <td class="align-middle text-left">{{ item.month }}</td>
                                <td class="align-middle text-right">{{ item.contribution }}</td>
                           

                            </tr>
                          </tbody>
                        </table>
                  </div>
            </div>

               
                
                <b-col md="12">
                  <br>
                </b-col>

                <b-col md="8">
                  <b-form-group>
                    <label>Desembolso: </label>
                    <v-select disabled placeholder="Seleccione un desembolso" class="w-100" :filterable="false" label="full_name" v-model="check" @search="SearchChecks" :options="checks"></v-select>
                    <small v-if="errors.id_check" class="form-text text-danger" >Seleccione un desembolso</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group  label-cols-sm="7" label="Aportes :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="settlement_contributions.accumulated_contribution"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Descuento :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="settlement_contributions.discount"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Total :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="settlement_contributions.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >No cuenta con aportes</small>
                  </b-form-group>
                </b-col>

                <b-col md="3"></b-col>
                <b-col md="2">
                  <b-button type="button" :disabled="settlement_contributions.file.length == 0" @click="OpenFile" class="form-control" variant="warning" ><i class="fas fa-file"></i> Ver</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="button"  @click="OpenExcel" class="form-control" variant="success" ><i class="fas fa-file-excel"></i> Excel</b-button>
                </b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/liquidacion-aporte/listar' }" append >REGRESAR</b-link>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
    <ModalContribution />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
import ModalContribution from './components/ModalContribution'
import ContributionDetail from './components/ContributionDetail'
export default {
  name: "UsuarioAdd",
  props:["id_settlement_contribution"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
      ModalContribution,
      ContributionDetail,
  },
  data() {
    return {
      isLoading: false,
      module: 'Contribution',
      role: 3,
      settlement_contributions: {
          id_settlement_contribution:'',
          id_partner:'',
          id_check:'',
          number:'',
          payment_method:'008',
          file:'',
          change_file:'',
          application_date:moment(new Date()).local().format("YYYY-MM-DD"),
          disbursement_date:moment(new Date()).local().format("YYYY-MM-DD"),
          observation:'',
          accumulated_contribution:'0.00',
          accumulated_burial:'0.00',
          discount:'0.00',
          total:'0.00',
          state:1,
      },
      settlement_contributions_detail:[],
      file:null,
      partners: [],
      partner:null,
      checks: [],
      check:null,
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      twist_type:[
        {value:'1',text:'Sepelio'},
        {value:'2',text:'Liquidación de Aportes'},
        {value:'3',text:'Gasto Administrativo'},
        {value:'4',text:'Prestamo Ordinario'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        id_partner: false,
        payment_method: false,
        number: false,
        application_date: false,
        disbursement_date: false,
        id_check: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewSettlementContribution();
  },
  methods: {
    ViewSettlementContribution,
    EditSettlementContribution,
    Validate,
    SearchChecks,
    onFileChange,
    ValidateAcceptSettlementContribution,
    AcceptSettlementContribution,
    ValidateCancelSettlementContribution,
    CancelSettlementContribution,

    Discount,
    OpenFile,
    OpenExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function OpenFile() {
  let me = this;
  let url = me.url_base + this.settlement_contributions.file;
  window.open(url,'_blank');

}

function OpenExcel() {
  let me = this;
  let url = me.url_base + "settlement-contribution-excel/"+this.settlement_contributions.id_settlement_contribution;
  window.open(url,'_blank');

}



function Discount() {
  this.settlement_contributions.discount = this.settlement_contributions.discount.length == 0 ? 0 : this.settlement_contributions.discount;
  this.settlement_contributions.total = parseFloat(this.settlement_contributions.accumulated_contribution) + parseFloat(this.settlement_contributions.accumulated_burial) - parseFloat(this.settlement_contributions.discount);

  this.settlement_contributions.discount = parseFloat(this.settlement_contributions.discount).toFixed(2);
  this.settlement_contributions.total = parseFloat(this.settlement_contributions.total).toFixed(2);
}

function ViewSettlementContribution() {
  let id_settlement_contribution = je.decrypt(this.id_settlement_contribution);
  let me = this;
  let url = me.url_base + "settlement-contribution/view/"+id_settlement_contribution;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.settlement_contributions.id_settlement_contribution = response.data.result.settlement_contributions.id_settlement_contribution;
        me.settlement_contributions.id_partner = response.data.result.settlement_contributions.id_partner;
        me.settlement_contributions.id_check = response.data.result.settlement_contributions.id_check;
        me.settlement_contributions.number = response.data.result.settlement_contributions.number;
        me.settlement_contributions.payment_method = response.data.result.settlement_contributions.payment_method;
        me.settlement_contributions.file = response.data.result.settlement_contributions.file;
        me.settlement_contributions.application_date = response.data.result.settlement_contributions.application_date;
        me.settlement_contributions.disbursement_date = response.data.result.settlement_contributions.disbursement_date;
        me.settlement_contributions.observation = response.data.result.settlement_contributions.observation;
        me.settlement_contributions.accumulated_contribution = response.data.result.settlement_contributions.accumulated_contribution;
        me.settlement_contributions.accumulated_burial = response.data.result.settlement_contributions.accumulated_burial;
        me.settlement_contributions.discount = response.data.result.settlement_contributions.discount;
        me.settlement_contributions.total = response.data.result.settlement_contributions.total;
        me.settlement_contributions.state = response.data.result.settlement_contributions.state;
        me.partner = {id:response.data.result.settlement_contributions.id_partner, full_name: response.data.result.settlement_contributions.partner_name + " - "+response.data.result.settlement_contributions.partner_document_number }
        me.check = {id:response.data.result.settlement_contributions.id_check, full_name: response.data.result.settlement_contributions.check }
        me.settlement_contributions_detail = response.data.result.settlement_contributions_detail;


    }else{
      me.settlement_contributions.number = '';
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}

function onFileChange(e) {
  this.settlement_contributions.change_file = e.target.files[0];
}

function SearchChecks(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-checks/" + this.settlement_contributions.id_partner+"/2";
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.checks = response.data;
            loading(false);
      })
    }
}

function ValidateAcceptSettlementContribution() {
   this.errors.id_partner = this.partner == null ? true : false;
  this.errors.number = this.settlement_contributions.number.length == 0 ? true : false;
  this.errors.disbursement_date = this.settlement_contributions.disbursement_date.length == 0 ? true : false;
  this.errors.id_check = this.check == null ? true : false;
  this.errors.total = parseFloat(this.settlement_contributions.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_check) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de aceptar la solicitud de liquidación de aporte ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AcceptSettlementContribution();
    }
  });
}

function AcceptSettlementContribution() {
  let me = this;
  let url = me.url_base + "settlement-contribution/accept-settlement-contribution";
  let data = new FormData();
  data.append("id_settlement_contribution", me.settlement_contributions.id_settlement_contribution);
  data.append("id_partner", me.partner.id);
  data.append("id_check", me.check.id);
  data.append("id_user", me.user.id_user);
  data.append("number", me.settlement_contributions.number);
  data.append("file", me.settlement_contributions.change_file);
  data.append("application_date", me.settlement_contributions.application_date);
  data.append("disbursement_date", me.settlement_contributions.disbursement_date);
  data.append("observation", me.settlement_contributions.observation);
  data.append("accumulated_contribution", me.settlement_contributions.accumulated_contribution);
  data.append("accumulated_burial", me.settlement_contributions.accumulated_burial);
  data.append("discount", me.settlement_contributions.discount);
  data.append("total", me.settlement_contributions.total);
  data.append("state", 2);
  data.append("settlement_contributions_detail", JSON.stringify(me.settlement_contributions_detail));
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.settlement_contributions.file = response.data.result.file;
        me.settlement_contributions.state = response.data.result.state;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ValidateCancelSettlementContribution() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.number = this.settlement_contributions.number.length == 0 ? true : false;
  this.errors.disbursement_date = this.settlement_contributions.disbursement_date.length == 0 ? true : false;
  this.errors.total = parseFloat(this.settlement_contributions.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de rechazar la solicitud de liquidación de aporte ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.CancelSettlementContribution();
    }
  });
}

function CancelSettlementContribution() {
  let me = this;
  let url = me.url_base + "settlement-contribution/cancel-settlement-contribution";
  let data = new FormData();
  data.append("id_settlement_contribution", me.settlement_contributions.id_settlement_contribution);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("number", me.settlement_contributions.number);
  data.append("file", me.settlement_contributions.change_file);
  data.append("application_date", me.settlement_contributions.application_date);
  data.append("disbursement_date", me.settlement_contributions.disbursement_date);
  data.append("observation", me.settlement_contributions.observation);
  data.append("accumulated_contribution", me.settlement_contributions.accumulated_contribution);
  data.append("accumulated_burial", me.settlement_contributions.accumulated_burial);
  data.append("discount", me.settlement_contributions.discount);
  data.append("total", me.settlement_contributions.total);
  data.append("state", 0);
  data.append("settlement_contributions_detail", JSON.stringify(me.settlement_contributions_detail));
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.settlement_contributions.state = response.data.result.state;
        me.settlement_contributions.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditSettlementContribution() {
  let me = this;
  let url = me.url_base + "settlement-contribution/edit";
  let data = new FormData();
  data.append("id_settlement_contribution", me.settlement_contributions.id_settlement_contribution);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("number", me.settlement_contributions.number);
  data.append("file", me.settlement_contributions.change_file);
  data.append("application_date", me.settlement_contributions.application_date);
  data.append("disbursement_date", me.settlement_contributions.disbursement_date);
  data.append("observation", me.settlement_contributions.observation);
  data.append("accumulated_contribution", me.settlement_contributions.accumulated_contribution);
  data.append("accumulated_burial", me.settlement_contributions.accumulated_burial);
  data.append("discount", me.settlement_contributions.discount);
  data.append("total", me.settlement_contributions.total);
  data.append("state", me.settlement_contributions.state);
  data.append("settlement_contributions_detail", JSON.stringify(me.settlement_contributions_detail));
  
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.settlement_contributions.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.settlement_contributions_detail = this.settlement_contributions_detail.length == 0 ? true : false;
  this.errors.total = parseFloat(this.settlement_contributions.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.settlement_contributions_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar la liquidación de aporte ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditSettlementContribution();
    }
  });
}
</script>
